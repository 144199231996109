import { updatetriggerSearchAfterUpdateValue, searchBlockPickerClose, searchBlockPickerOpen, updateSearchBarOptions } from "./search-bar.js"
import { submit as dynamicSearchSubmit } from "./dynamic-search.js";
import { breakpoints, lockout, debounce } from "./utilities.js"
import "./jquery.hoverintent.js";


var activeClass = 'is-active';
var mobileHiddenClass = 'c-destination__hide-mobile';

function init() {
	if (!$('.js-destination-picker-trigger').length) {
		return;
	}

	attachEvents();
}

function attachEvents() {

	window.site.body.on('click keypress', '.js-destination-picker-trigger', function(e) {
		if (e.type === 'click' || ((e.type === 'keypress') && e.which === 13)) {
			if (!pickerIsOpen()) {
				openDestinationsPicker($(this));
				getCurrentPicker().find('.js-destination-picker-button').first().focus();
			} else {
				closeDestinationsPicker(true, true);
			}
			e.preventDefault();
		}
	});

	window.site.body.on('click', function(e) {

		if (!pickerIsOpen()) {
			return;
		}

		if ($(e.target).hasClass('js-dynamic-search-when')
			|| $(e.target).hasClass('js-datepicker-wrapper')
			|| $(e.target).hasClass('js-folks-selector-departing')
			|| $(e.target).hasClass('js-folks-selector-trigger')
			|| $(e.target).hasClass('js-airport-picker-trigger')
			|| $(e.target).hasClass('js-airport-picker-facade')) {
			closeDestinationsPicker(false, true);
			return;
		}

		// If not a click on the destination picker
		if (!$(e.target).parents('.js-destination-picker').length
			&& !$(e.target).closest('.js-destination-picker').length
			&& !$(e.target).parents('.m-search-block__form__row--destination').length
			&& !$(e.target).hasClass('js-destination-picker-trigger')
			&& !$(e.target).hasClass('js-destination-picker-button')
			&& !$(e.target).hasClass('js-destination-picker-back')
			&& !$(e.target).hasClass('js-dynamic-search-when')
			&& !$(e.target).hasClass('m-search-block__form__row--destination')) {

			if ($('.js-destination-picker').hasClass(activeClass)) {
				closeDestinationsPicker(true, true);
			}

		}

	});

	window.site.body.on('click', '.js-destination-picker-button', function(e) {
		handleButtonActivated($(this), true);
		e.preventDefault();
	});

	// ios mouseover cancels the click
	if (!isIos()) {
		window.site.body.hoverIntent({
			over: function() {
				handleButtonActivated($(this), false);
			},
			selector: '.js-destination-picker-button'
		});
	}

	window.site.body.on('click', '.js-destination-picker-button-mobile', function(e) {
		handleMobileButtonClick($(this));
		e.preventDefault();
	});

	window.site.body.on('click', '.js-destination-picker-back', function() {
		var activeColumn = getCurrentPicker().find('.js-destination-picker-col:visible');

		if (activeColumn.hasClass('js-destination-picker-regions')) {
			activeColumn.addClass(mobileHiddenClass);
			getCurrentPicker().find('.js-destination-picker-countries').removeClass(mobileHiddenClass);
		}

		if (activeColumn.hasClass('js-destination-picker-subregions')) {
			activeColumn.addClass(mobileHiddenClass);
			getCurrentPicker().find('.js-destination-picker-regions').removeClass(mobileHiddenClass);
		}
		setBackButtonDisplay();
	});


	window.site.body.on('click', '.js-destination-picker-apply, .js-destination-picker-close', function(e) {

		closeDestinationsPicker(true, true);

	});


	window.site.win.on('resize', debounce(setBackButtonDisplay))
}

function getCurrentPicker() {
	return $('.js-destination-picker:visible').closest('.js-selector-destination-wrapper');
}

function pickerIsOpen() {
	return getCurrentPicker().find('.js-destination-picker').hasClass(activeClass);
}

function setBackButtonDisplay() {
	var backButton = getCurrentPicker().find('.js-destination-picker-back');
	if (getCurrentPicker().find('.js-destination-picker-col:visible').hasClass('js-destination-picker-countries')) {
		backButton.addClass(mobileHiddenClass);
	} else {
		backButton.removeClass(mobileHiddenClass);
	}
}

function openDestinationsPicker(trigger) {
	closeDestinationsPicker(false, false);
	trigger.closest('.js-selector-destination-wrapper').find('.js-destination-picker').addClass('is-active');
	trigger.closest('.js-selector-destination-wrapper').find('.js-destination-picker-facade-container').addClass('is-active');
	// set scroll
	if (window.site.win.width() < breakpoints.tablet) {

		lockout.on()
		$('.l-nav').css('z-index', '12');
	}
	window.site.html.addClass('destinations-picker-open');
	searchBlockPickerOpen(trigger);

	$('.js-destination-picker-apply').removeAttr('disabled');
	checkImageDisplay();
	setInitialMobileState();
	trigger.closest('.js-selector-destination-wrapper').attr('data-desination-initial', $('.js-folks-selector-wrapper:visible').find('.js-folks-selector-destination').val());
}

function closeDestinationsPicker(triggerSearch, triggerUpdateSearchBarOptions) {
	updatetriggerSearchAfterUpdateValue(triggerSearch) &&
		(getCurrentPicker().closest('form').hasClass('js-dynamic-search') && !$('.js-destination-picker-no-auto-submit').length);

	if (triggerUpdateSearchBarOptions) {
		updateSearchBarOptions();
	}
	if (triggerSearch) {
		if (getCurrentPicker().closest('form').hasClass('js-dynamic-search')
			&& !$('.js-destination-picker-no-auto-submit').length
		) {
			dynamicSearchSubmit(false);
		}
	}
	// set scroll
	window.site.html.removeClass('destinations-picker-open');
	searchBlockPickerClose();

	if (window.site.win.width() < breakpoints.tablet) {
		lockout.off()
		$('.l-nav').css('z-index', '11');
	}
	$('.js-destination-picker').removeClass(activeClass);
	$('.js-destination-picker-facade-container').removeClass('is-active');
}

function setInitialMobileState() {
	var selectedButton = getCurrentPicker().find('.js-destination-picker-col .js-destination-picker-button.is-selected').first();
	getCurrentPicker().find('.js-destination-picker-col').addClass(mobileHiddenClass);
	if (selectedButton.length) {

		// set columns
		selectedButton.closest('.js-destination-picker-col').removeClass(mobileHiddenClass);
		getCurrentPicker().find('.c-destinations-picker__list:not(".js-destinations-picker-list-first")').removeClass('is-active');
		selectedButton.closest('.c-destinations-picker__list').addClass('is-active');
		selectedButton.addClass('is-active');

		// reset the buttons
		getCurrentPicker().find('.js-destination-picker-button').removeClass('is-active');
		getCurrentPicker().find('.js-destination-picker-button-mobile').removeClass('is-active');
		selectedButton.addClass('is-active');

		var region = selectedButton.closest('.c-destinations-picker__list').attr('data-destination-subregions-for-region');
		var country;
		if (region) {
			var regionButton = getCurrentPicker().find('[data-destination-region="' + region + '"]');
			regionButton.addClass('is-active');
			regionButton.closest('.js-destinations-picker-list-second-level').addClass('is-active');
			country = regionButton.closest('.c-destinations-picker__list').attr('data-destination-regions-for-country');
		}
		if (!country) {
			country = selectedButton.closest('.c-destinations-picker__list').attr('data-destination-regions-for-country');
		}
		if (country) {
			getCurrentPicker().find('[data-destination-country="' + country + '"]:not(".js-destination-picker-button-mobile")').addClass('is-active');
		}

	} else {
		getCurrentPicker().find('.js-destination-picker-countries').removeClass(mobileHiddenClass);
	}
	setBackButtonDisplay();
}

function handleMobileButtonClick(trigger) {

	if (trigger.attr('data-destination-country')) {
		showRegionsForCountry(trigger.attr('data-destination-country'));
		getCurrentPicker().find('.js-destination-picker-countries').addClass(mobileHiddenClass);
		getCurrentPicker().find('.js-destination-picker-regions').removeClass(mobileHiddenClass);
	}

	if (trigger.attr('data-destination-region')) {
		showSubregionsForRegion(trigger.attr('data-destination-region'));
		getCurrentPicker().find('.js-destination-picker-regions').addClass(mobileHiddenClass);
		getCurrentPicker().find('.js-destination-picker-subregions').removeClass(mobileHiddenClass);
	}

	setBackButtonDisplay();

}

function handleButtonActivated(selectedButton, setValue) {

	if (setValue) {

		// if on the homepage
		if (!getCurrentPicker().closest('form').hasClass('js-dynamic-search')) {
			getCurrentPicker().find('.js-destination-picker-input').val(selectedButton.attr('data-destination-value')).trigger('change');
			getCurrentPicker().find('.js-destination-picker-facade').val(selectedButton.text().trim());
		}
		else { // if on a search page
			getCurrentPicker().closest('form').find('.js-destination-picker-input').val(selectedButton.attr('data-destination-value')).trigger('change');
			getCurrentPicker().closest('form').find('.js-destination-picker-facade').val(selectedButton.text().trim()).trigger('change');
		}

		getCurrentPicker().find('.js-destination-picker-button').removeClass('is-selected');
		selectedButton.addClass('is-selected');
	}

	if (selectedButton.hasClass('js-destination-picker-button-country')) {
		showRegionsForCountry(selectedButton.attr('data-destination-country'), setValue);
	}

	if (selectedButton.hasClass('js-destination-picker-button-region')) {
		showSubregionsForRegion(selectedButton.attr('data-destination-value'), setValue);
	}

	if (setValue && selectedButton.hasClass('js-destination-picker-button-sub-region')) {
		getCurrentPicker().find('.js-destination-picker-button-sub-region').removeClass(activeClass);
		selectedButton.addClass(activeClass);
	}

	checkImageDisplay();
}

function showRegionsForCountry(country) {
	// show
	getCurrentPicker().find('[data-destination-regions-for-country]').removeClass(activeClass);
	getCurrentPicker().find('[data-destination-regions-for-country="' + country + '"]').addClass(activeClass);

	// toggle the buttons
	getCurrentPicker().find('.js-destination-picker-button-region').removeClass(activeClass);
	getCurrentPicker().find('.js-destination-picker-button-sub-region').removeClass(activeClass);
	getCurrentPicker().find('.js-destination-picker-button-country').removeClass(activeClass);
	getCurrentPicker().find('[data-destination-subregions-for-region]').removeClass(activeClass);
	getCurrentPicker().find('.js-destination-picker-button[data-destination-value="' + country + '"]').addClass(activeClass);
}

function showSubregionsForRegion(region) {
	// show
	getCurrentPicker().find('[data-destination-subregions-for-region]').removeClass(activeClass);
	getCurrentPicker().find('[data-destination-subregions-for-region="' + region + '"]').addClass(activeClass);

	// toggle the buttons
	getCurrentPicker().find('.js-destination-picker-button-sub-region').removeClass(activeClass);
	getCurrentPicker().find('.js-destination-picker-button-region').removeClass(activeClass);
	getCurrentPicker().find('.js-destination-picker-button[data-destination-value="' + region + '"]').addClass(activeClass);
}

function checkImageDisplay() {

	if (!getCurrentPicker().find('.js-destinations-picker-list-second-level.is-active').length &&
		!getCurrentPicker().find('.js-destinations-picker-list-third-level.is-active').length &&
		getCurrentPicker().find('.js-destination-picker-image').length) {
		getCurrentPicker().find('.js-destination-picker-col').not('.js-destination-picker-countries').addClass('is-hidden');
		getCurrentPicker().find('.c-destinations-picker__col--image').removeClass('is-hidden');
	} else {
		getCurrentPicker().find('.js-destination-picker-col').removeClass('is-hidden');
		getCurrentPicker().find('.c-destinations-picker__col--image').addClass('is-hidden');
	}

}

function isIos() {
	return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}


export default init;
export { closeDestinationsPicker as close }
